import useRoutes from "./routes";
import {BrowserRouter as Router} from 'react-router-dom';
import {useContext} from "react";
import AuthContext from "./context/authContext";
import {Header} from "./components/Header";
import {SideNav} from "./components/SideNav";

function App() {
    const {auth} = useContext(AuthContext);
    const routes = useRoutes(auth);
    return (
      <div className={"flex w-full flex-col"}>
          <Router>
              {auth && <Header/>}
              <div className={"w-full flex px-[12px] gap-[12px]"}>
                  {/*{auth && <SideNav/>}*/}
                  {routes}
              </div>
          </Router>
      </div>
    );
}

export default App;
