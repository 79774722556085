import axios from "axios";
import {BaseURL} from "../variables/Variables";

export async function LoginAPI({username, password}) {
    try {
        const res = await axios.post(`${BaseURL}/auth/`, {username: username, password: password});
        return res.data
    } catch (error) {
        return error.response.data
    }
}

export async function MeAPI() {
    try {
        const res = await axios.get(`${BaseURL}/users/me/`);
        return res.data
    } catch (error) {
        return error.response.data
    }
}
