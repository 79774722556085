import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import {HomePage} from "./pages/home/HomePage";
import {LoginPage} from "./pages/login/LoginPage";
import {BuyForm} from "./pages/buyForm/BuyForm";

const useRoutes = (isAuthenticated) => {
    if(isAuthenticated){
        return (
            <Routes>
                <Route path="/:id/buy_form" element={<BuyForm/>} />
                <Route path="/main" element={<HomePage/>} />
                <Route path="*" element={<Navigate to="/main" />} />
            </Routes>
        );
    }
    return (
        <Routes>
            <Route path="/" element={<LoginPage/>} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}

export default useRoutes;
