import {Button, User} from "@nextui-org/react";
import {ApproveDetailsModal} from "./ApproveDetailsModal";

export const ApproveCard = ({id, name, username, position, avatar, updated_at, approveUI, setListUpdated, status}) => {
    return(
        <div className={`rounded-md p-[12px] w-full ${status === "Возвращен" ? "border-[red]" : "border-[#6F6F6F]"}  border-[1px] bg-[#323232] flex flex-col gap-[12px] items-start`}>
            <div className={"flex items-center gap-[12px]"}>
                <p className={"text-white text-[16px] font-bold"}>{name}</p>
            </div>
            <p className={"text-[12px] text-white"}>{status}</p>
            <User
                name={username}
                description={position}
                avatarProps={{
                    src: avatar
                }}
                className={"text-white"}
            />
            <p className={"text-[10px] text-white"}>
                {updated_at}
            </p>
            <ApproveDetailsModal id={id} name={name} approveUI={approveUI} setListUpdated={setListUpdated} disabled={status !== "Возвращен"} status={status}/>
        </div>
    )
}
