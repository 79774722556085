import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button, Select, SelectItem
} from "@nextui-org/react";
import {useState} from "react";
import {useNavigate, useParams} from "react-router";
import axios from "axios";
import {BaseURL} from "../../variables/Variables";


export const BuyForm = () => {
    const {id} = useParams();
    console.log(id);
    const [sendData, setSendData] = useState({
        "name": "",
        "comment": "",
        "delivery_date": "",
        "supplier": "",
    })
    const history = useNavigate();
    const [rows, setRows] = useState([{
        "id": "",
        "name": "",
        "pack": "",
        "count": 0,
        "price": 0,
        "sum": "",
    }]);
    const columns = [
        {
            key: "id",
            label: "Артикул",
        },
        {
            key: "name",
            label: "Наименование товара",
        },
        {
            key: "count",
            label: "Количество",
        },
        {
            key: "pack",
            label: "Упаковка",
        },
        {
            key: "price",
            label: "Цена за единицу",
        },
        {
            key: "sum",
            label: "Сумма",
        },
        {
            key: "delete",
            label: "Удалить?",
        }
    ];
    const handleCost = () => {
        const temp_rows = [...rows]
        setRows(temp_rows);
    }

    const deleteRow = (index) => {
        const temp_rows = [...rows]
        temp_rows.splice(index, 1);
        setRows(temp_rows);
    }

    const changeInput = (e, index, name) => {
        const temp_rows = [...rows];
        temp_rows[index][`${name}`] = e.target.value;
        if (name === "count" || name === "price"){
            temp_rows[index].sum = temp_rows[index].count * temp_rows[index].price;
        }
        setRows(temp_rows);
    }

    const submitData = e => {
        e.preventDefault();
        console.log(rows);
        const data = new FormData();

        data.append("name", sendData.name);
        data.append("template_id", id);
        data.append("comment", sendData.comment);
        data.append("delivery_date", sendData.delivery_date);
        data.append("supplier", sendData.supplier);
        data.append("rows", JSON.stringify(rows));
        data.append("template", JSON.stringify(""));

        axios.post(`${BaseURL}/users/approve/create/`, data).then(
            res => {
                history("/")
            }
        ).catch(
            err => {console.log(err)}
        )
    }

    return(
        <div className={"py-[12px] flex flex-col gap-[12px] items-start"}>
            <Button onClick={() => {history('/')}}>
                Назад
            </Button>
            <form onSubmit={submitData} className={"flex flex-col gap-[16px]"}>
                <Input
                    label={"Название"}
                    required={true}
                    type={"text"}
                    name={"name"}
                    onChange={(e) => {setSendData({...sendData, name: e.target.value})}}
                />
                <Input
                    label={"Поставщик"}
                    required={true}
                    type={"text"}
                    name={"supplier"}
                    onChange={(e) => {setSendData({...sendData, supplier: e.target.value})}}
                />
                <Input
                    label={"Ожидаемая дата поставки"}
                    required={true}
                    type={"date"}
                    name={"delivery_date"}
                    onChange={(e) => {setSendData({...sendData, delivery_date: e.target.value})}}
                />
                <Input
                    label={"Комментарий и инструкция"}
                    required={true}
                    type={"text"}
                    name={"comment"}
                    onChange={(e) => {setSendData({...sendData, comment: e.target.value})}}
                />
                <Table aria-label="Example table with dynamic content">
                    <TableHeader columns={columns}>
                        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                    </TableHeader>
                    <TableBody>
                        {
                            rows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell className={"rounded-none"}>
                                        <Input
                                            required={true}
                                            value={row.id}
                                            onChange={e => {changeInput(e, index, "id")}}
                                        />
                                    </TableCell>
                                    <TableCell className={"rounded-none"}>
                                        <Input
                                            required={true}
                                            value={row.name}
                                            onChange={e => {changeInput(e, index, "name")}}
                                        />
                                    </TableCell>
                                    <TableCell className={"rounded-none"}>
                                        <Input
                                            required={true}
                                            value={row.count}
                                            type={"number"}
                                            onChange={e => {changeInput(e, index, "count");
                                                handleCost();}}
                                        />
                                    </TableCell>
                                    <TableCell className={"rounded-none"}>
                                        <Select
                                            required={true}
                                            value={""}
                                            onChange={e => {
                                                row.pack = e.target.value
                                            }}
                                            className="max-w-xs w-[100px]"
                                        >
                                            <SelectItem key={"Шт."} value={"Шт."}>Шт.</SelectItem>
                                            <SelectItem key={"Литр"} value={"Литр"}>Литр</SelectItem>
                                            <SelectItem key={"Банка"} value={"Банка"}>Банка</SelectItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell className={"rounded-none"}>
                                        <Input
                                            required={true}
                                            value={row.price}
                                            type={"number"}
                                            onChange={e => {changeInput(e, index, "price")
                                                handleCost();}}
                                        />
                                    </TableCell>
                                    <TableCell className={"rounded-none"}>
                                        <Input
                                            disabled={true}
                                            value={row.sum}
                                        />
                                    </TableCell>
                                    <TableCell className={"rounded-none"}>
                                        {
                                            index > 0 && <Button onClick={() => {deleteRow(index)}}>Удалить</Button>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <div className={"flex gap-[12px]"}>
                    <Button onClick={() => {
                        const temp_rows = [...rows]
                        temp_rows.push({
                            "id": "",
                            "name": "",
                            "pack": "",
                            "count": 0,
                            "price": 0,
                            "sum": ""
                        });
                        setRows(temp_rows);
                    }}>
                        Добавить строчку
                    </Button>
                    <Button
                        type={"submit"}>
                        Отправить на проверку
                    </Button>
                </div>
            </form>
        </div>
    )
}
