import {Button, Input, Spinner} from "@nextui-org/react";
import Logo from "../../assets/MainLogo.png";
import {useState} from "react";
import {LoginAPI} from "../../api/ApiCalls";

export const LoginPage = () => {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [submitData, setSubmitData] = useState(
        {
            username: "",
            password: ""
        }
    )
    const submit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const res = await LoginAPI({...submitData});
        if (!res?.token){
            setIsLoading(false);
            setError(true);
        }else{
            localStorage.setItem("authToken", res.token);
            window.location.reload();
        }
    }

    const handleChange = e => {
        setError(false);
        setSubmitData({...submitData, [e.target.name]: e.target.value});
    }

    return(
        <div className={"h-screen w-full"}>
            <div className={"w-full h-full flex flex-col justify-center items-center p-[12px]"}>
                <div className={"absolute top-[20px] max-w-[500px] p-[24px]"}>
                    <img draggable={false} src={Logo} className={"max-w-[160px]"}/>
                </div>
                <form
                    className={"flex flex-col bg-[white] p-[10px] w-full max-w-[324px] gap-[16px] rounded-lg border-[#FCE345] border-[1px]"}
                    onSubmit={submit}>
                    {error && <p>Неверное имя пользователя / пароль</p>}
                    <h1 className={"text-[18px] font-bold"}>Вход</h1>
                    <Input
                        disabled={isLoading}
                        required={true}
                        radius={"sm"}
                        labelPlacement={"inside"}
                        value={submitData.username}
                        name={"username"}
                        type={"text"}
                        placeholder={"Логин"}
                        classNames={{
                            inputWrapper: [
                                "input-wrapper"
                            ],
                        }}
                        onChange={handleChange}/>
                    <Input
                        disabled={isLoading}
                        required={true}
                        radius={"sm"}
                        labelPlacement={"inside"}
                        value={submitData.password}
                        name={"password"}
                        type={"password"}
                        placeholder={"Пароль"}
                        classNames={{
                            inputWrapper: [
                                "input-wrapper"
                            ],
                        }}
                        onChange={handleChange}/>
                    <Button type={"submit"}
                            className={"button"}
                            disabled={isLoading}>
                        <Spinner size={"sm"} color={"secondary"}/>
                        Войти
                        <Spinner size={"sm"} color={isLoading ? "primary" : "secondary"}/>
                    </Button>
                </form>
                <div className={"absolute bottom-[20px] max-w-[500px] p-[24px]"}>
                    <p className={"text-center text-[8px] text-[white] font-bold"}>Информация предоставленная в сервисе <span className={"underline"}>aspsystems.kz</span> является полностью конфиденциальной. Факт копирования, распространения информации карается законами РК.</p>
                    <p className={"text-center text-[10px] text-[white] font-bold mt-[20px]"}>Все права защищены - © ТОО "ASP Holding"</p>
                </div>
            </div>
        </div>
    )
}
