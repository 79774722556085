import {ApproveCard} from "../../components/ApproveCard";
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Spinner,
    getKeyValue,
} from "@nextui-org/react";
import {useInfiniteScroll} from "@nextui-org/use-infinite-scroll";
import {useAsyncList} from "@react-stately/data";
import {useEffect, useState} from "react";
import {CreateApprovalModal} from "../../components/CreateApprovalModal";
import {BaseURL} from "../../variables/Variables";
import axios from "axios";
import {ApproveDetailsModal} from "../../components/ApproveDetailsModal";

export const HomePage = () => {
    const [clickedItem, setClickedItem] = useState({id: null, open:false, name: ""});
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [newApprovals, setNewApprovals] = useState([]);
    const [listUpdated, setListUpdated] = useState(false);

    let list = useAsyncList({
        async load({signal, cursor}) {

            if (!cursor) {
                setIsLoading(false);
            }

            const res = await axios.get(cursor || `${BaseURL}/users/approve/list/`, {signal});

            let json = res.data

            setHasMore(json.next !== null);

            return {
                items: json.results,
                cursor: json.next,
            };
        },
    });

    useEffect(() => {
        axios.get(`${BaseURL}/users/approve/new/list/`).then(
            res => {
                setNewApprovals(res.data)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }, [listUpdated]);

    const [loaderRef, scrollerRef] = useInfiniteScroll({hasMore, onLoadMore: list.loadMore});

    return(
        <div className={"page-container"}>
            <div className={'flex gap-[30px] mt-[5px] w-full justify-start items-start'}>
                {
                    newApprovals.length > 0 &&
                    <div className={"flex flex-col gap-[12px]"}>
                        <h2 className={"text-white font-bold py-[4px]"}>Новые</h2>
                        <div className={"height-transformer w-full max-w-full min-w-[324px] md:max-w-[324px] flex flex-col gap-[12px] rounded-md"}>
                            {
                                newApprovals.map((item, index) => (
                                    <ApproveCard
                                        key={index}
                                        id={item.id}
                                        name={item.name}
                                        username={item.user.full_name}
                                        avatar={item.user.avatar}
                                        updated_at={item.updated_at}
                                        approveUI={true}
                                        setListUpdated={setListUpdated}
                                        status={item.status}
                                    />
                                ))
                            }
                        </div>
                    </div>
                }
                <div className={"flex flex-col gap-[12px] w-full"}>
                    <div className={"flex items-center gap-[16px]"}>
                        <h2 className={"text-white font-bold"}>Все обращения</h2>
                        <CreateApprovalModal list={list}/>
                    </div>
                    <div className={"height-transformer w-full flex flex-col gap-[12px] rounded-md"}>
                        <Table
                            isVirtualized={true}
                            isHeaderSticky
                            aria-label="approve table"
                            baseRef={scrollerRef}
                            bottomContent={
                                hasMore ? (
                                    <div className="flex w-full justify-center">
                                        <Spinner ref={loaderRef} color="white" />
                                    </div>
                                ) : null
                            }
                            classNames={{
                                base: "max-h-full overflow-scroll",
                                wrapper: "bg-[#323232] border-[#6F6F6F] border-[1px] rounded-md"
                            }}
                        >
                            <TableHeader>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="priority">Приоритет</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="category_name">Название категории</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="name">Название</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="status">Статус</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="user">Отправитель</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="created_at">Дата создания</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="updated_at">Дата последнего изменения</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="last_user">Последний подтвердивший</TableColumn>
                            </TableHeader>
                            <TableBody
                                isLoading={isLoading}
                                items={list.items}
                                loadingContent={<Spinner color="white" />}
                            >
                                {(item) => (
                                    <TableRow key={item.id}
                                              onClick={() => {
                                                  setClickedItem({id:item.id, open: true, name: item.name});
                                              }}>
                                        {(columnKey) => <TableCell className={`text-white min-w-[100px]`}>{getKeyValue(item, columnKey)}</TableCell>}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <ApproveDetailsModal disabled={true} id={clickedItem.id} open={clickedItem.open} external={true} name={clickedItem.name} setItem={setClickedItem} setListUpdated={setListUpdated}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
